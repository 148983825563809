import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Footer } from "./footer";

export const FynBuddy = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="fynbuddy">
            <Navigation />
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row intro-content">
                            {/* First Column */}
                            <div className="col-md-6 intro-text">
                                <h1>
                                    <span>Transform BFSI services with</span>
                                    <br />
                                    <span>fast, secure, and scalable AI</span>
                                </h1>
                                <div>
                                    <p>Offer 24/7, instant banking customer service and personalize conversations across 35+ channels and 135+ languages with a human-like, secure AI-first platform.</p>
                                </div>
                                <button className="btn-custom">Book a demo</button>
                            </div>
                            {/* Second Column */}
                            <div className="col-md-6 intro-image">
                                <img src="./img/ai-in-bank.jpg" alt="AI in BFSI Industry" />
                            </div>
                            {/* Wave Pattern */}
                            <div className="wave-container">
                                <svg
                                    className="waves"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 24 150 40"
                                    preserveAspectRatio="none"
                                    shape-rendering="auto"
                                >
                                    <defs>
                                        <path
                                            id="gentle-wave"
                                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                                        ></path>
                                    </defs>
                                    <g className="moving-waves">
                                        <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgb(38, 154, 216)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(50, 160, 218)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="1" fill="rgb(63, 167, 221)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="2" fill="rgb(75, 173, 223)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(88, 179, 225)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="4" fill="rgb(100, 185, 228)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(113, 191, 230)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="6" fill="rgb(125, 197, 232)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(138, 203, 235)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgb(150, 209, 237)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="9" fill="rgb(163, 215, 239)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="10" fill="rgb(175, 221, 242)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="11" fill="rgb(188, 227, 244)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="12" fill="rgb(200, 233, 246)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgb(213, 239, 249)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="14" fill="rgb(225, 245, 251)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="15" fill="rgb(238, 251, 253)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgb(250, 255, 255)" />
                                        <use xlinkHref="#gentle-wave" x="48" y="17" fill="rgb(255, 255, 255)" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fynbuddy-container text-center">
                <div className="fynbuddy-header">
                    <div className="section-title fynbuddy-section-title">
                        <h2>Transform BFSI services with fast, secure, and scalable AI</h2>
                        <p>Offer 24/7, instant banking customer service and personalize conversations across 35+ channels and 135+ languages with a human-like, secure AI-first platform.</p>
                    </div>
                    <button className="btn-custom">Book a demo</button>
                    <div className="fynbuddy-header-image">
                        <img src="/img/blog-1.jpg" alt="BFSI Industry Image" />
                    </div>
                </div>

                <div className="fynbuddy-feature-1 d-flex">
                    {/* Left Side - Heading */}
                    <div className="feature-1-text">
                        <h4>Boost your ROI with leading AI, trusted by 1100+ enterprises</h4>
                    </div>
                    {/* Right Side - Stats Boxes */}
                    <div className="feature-stats">
                        <div className="stats-grid">
                            <div className="stat-box">
                                <p><strong>90%</strong><br />automation of financial queries</p>
                            </div>
                            <div className="stat-box">
                                <p><strong>40%</strong><br />increase in customer satisfaction</p>
                            </div>
                            <div className="stat-box">
                                <p><strong>60%</strong><br />reduction in operational costs</p>
                            </div>
                            <div className="stat-box">
                                <p><strong>50%</strong><br />boost in banking agent productivity</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-title fynbuddy-section-title">
                    <h3>From instant setup to zero wait times: Drive service excellence in BFSI</h3>
                    <p>Where AI efficiency meets human-like empathy: Achieve faster time to value with rapid setup, and resolve banking queries rapidly with less than 1% hallucinations.</p>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-1.jpg" alt="BFSI Industry Image" />
                    </div>

                    {/* Right Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Go live 2x faster with 150+ prebuilt templates and no-code AI</h4>
                        <p>
                            Deploy easy-to-use Generative AI-driven banking agents in seconds, without the need for coding or NLP training.
                            Achieve high flexibility at 60% lower operational costs - Integrate AI agents with 100+ out-of-the-box CRM and billing systems.
                        </p>
                    </div>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Trim wait times with anytime, anywhere banking</h4>
                        <p>
                            Deliver round-the-clock customer service, beyond banking agent working hours across diverse channels. Handle FAQs related to interest rates, loan eligibility, opening bank accounts, updating policies, cancellations, and more.
                        </p>
                    </div>

                    {/* Right Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-2.jpg" alt="BFSI Industry Image" />
                    </div>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-3.jpg" alt="BFSI Industry Image" />
                    </div>

                    {/* Right Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Drive meaningful interactions on the fly with voice AI</h4>
                        <p>Build customer connections with human-like conversations using voice AI agents capable of language switching and interruption handling. Handle scenarios with empathy like replacing or blocking cards, and responding to fraud alerts.</p>
                    </div>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Gain unmatched confidence with enterprise-grade security</h4>
                        <div>
                            <p>
                                Our top priority is privacy and security of your data! That’s why Yellow.ai is ISO certified, HIPAA, SOC2, GDPR, and PDPA compliant  – giving you peace of mind and the assurance that your data’s privacy and security are intact.
                            </p>
                            <ul>
                                <li>Role-based access control, single sign-on, OAuth, and SAML integration.</li>
                                <li>TLS 1.3 encryption in transit (TLS 1.2 supported for backward compatibility), AES 256 encryption at rest.</li>
                                <li>Pentests & vulnerability management on the code and platform levels; WAF, DDOS, IPS & IDS covered.</li>
                            </ul>
                        </div>
                    </div>

                    {/* Right Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-4.jpg" alt="BFSI Industry Image" />
                    </div>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-5.jpg" alt="BFSI Industry Image" />
                    </div>

                    {/* Right Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Enhance banking services with a perfect blend of AI+humans</h4>
                        <p>Transfer complex queries to live banking agents with historical context via chat summaries. Enable auto-suggestions based on past queries, transactions, policy purchases, etc using AI Omnichannel Agent Inbox.</p>
                    </div>
                </div>

                <div className="section-title fynbuddy-section-title">
                    <h3>Success Story: A Leading Bank Saves 51% OPEX with Vozzo.ai</h3>
                </div>

                <div className="fynbuddy-feature-2">
                    {/* Left Side - Image */}
                    <div className="fynbuddy-feature-2-image">
                        <img src="/img/bfsi-6.jpg" alt="BFSI Industry Image" />
                    </div>

                    {/* Right Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Bandhan Bank</h4>
                        <p className="story-text">
                            "With Vozzo.ai’s Conversational AI, we automated key banking processes, improving efficiency and customer engagement while reducing operational costs significantly."
                        </p>
                        <p className="story-author"><strong>Rajesh Kumar</strong></p>
                        <p className="story-designation">Head of Digital Transformation</p>
                        <button className="btn-custom">Read the story</button>
                    </div>
                </div>

                <div className="cta-section-end">
                    {/* Left Side - Text */}
                    <div className="fynbuddy-feature-2-text">
                        <h4>Incredibly Human-like AI Agents Built for Enterprise Scale & Security</h4>
                        <div>
                            <p>Zero Engineering Lift | 100+ integrations | Multi-LLM architecture</p>
                        </div>
                        <button className="btn-custom">Book a demo</button>
                    </div>

                    {/* Right Side - Image */}
                    <div className="cta-image-container">
                        <img src="/img/cta-image.jpg" alt="BFSI Industry Image" />
                    </div>
                </div>
                < Footer />
            </div>
        </div>
    );
};