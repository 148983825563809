import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Function to handle smooth scrolling with an offset
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const headerOffset = 83;
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const Navigation = (props) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Adjust scroll threshold if needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleSetActive = (path, sectionId) => {
    setActiveLink(path);
    if (sectionId) {
      scrollToSection(sectionId);
    } else if (path === "/") {
      scrollToTop();
    }
  };

  return (
    <nav id="menu" className={`navbar navbar-default navbar-fixed-top ${isScrolled ? "scrolled" : ""}`}>
      <div className="container">
        {/* Logo Section */}
        <div className="navbar-header">
          <Link to="/" onClick={() => handleSetActive("/")}>
            <img src={`${process.env.PUBLIC_URL}/img/vozzo-logo.jpg`} width={100} alt="Vozzo.ai" />
          </Link>
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>

        {/* Navigation Links & Button in One Line */}
        <div className="navbar-content">
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className={`nav navbar-nav navbar-center ${isScrolled ? "hidden-menu" : ""}`}>
              <li className={activeLink === "/" ? "active" : ""}>
                <Link to="/" onClick={() => handleSetActive("/")}>Home</Link>
              </li>
              <li className={activeLink === "/about" ? "active" : ""}>
                <Link to="/about" onClick={() => handleSetActive("/about")}>About</Link>
              </li>
              {/* <li className={activeLink === "/services" ? "active" : ""}>
                <Link to="/services" onClick={() => handleSetActive("/services")}>Services</Link>
              </li> */}
              <li className={activeLink === "/ai-services" ? "active" : ""}>
                <Link to="/ai-services" onClick={() => handleSetActive("/ai-services")}>Services</Link>
              </li>
              <li className={activeLink === "/live-demo" ? "active" : ""}>
                <Link to="/live-demo" onClick={() => handleSetActive("/live-demo")}>Live Demo</Link>
              </li>
              <li className={activeLink === "/contact" ? "active" : ""}>
                <Link to="/contact" onClick={() => handleSetActive("/contact")}>Contact</Link>
              </li>
              <li className={activeLink === "/blogs" ? "active" : ""}>
                <Link to="/blogs" onClick={() => handleSetActive("/blogs")}>Blogs</Link>
              </li>
            </ul>
          </div>

          {/* Schedule Demo Button */}
          <div className="navbar-btn">
            <Link to="/contact" className="btn btn-custom btn-lg page-scroll" onClick={() => handleSetActive("/contact")}>
              Book Demo
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};