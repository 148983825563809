import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";
import { Helmet } from 'react-helmet-async';

export const ServicesPage = (props) => {
    const data = props.data;
    const availablePages = ["fynbuddy"]; // List of services with existing pages

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="services">
            <Helmet>
                <title>AI-Powered Voice Bots for Various Industries</title>
                <meta name="description" content="Discover Vozzo.ai's tailored AI-powered voice bot solutions for various industries, including healthcare, retail, financial, and customer service. Revolutionize your communication today." />
            </Helmet>
            <Navigation />
            <div className="text-center services-section">
                <div className="section-title">
                    <h2>Custom AI Solutions for Every Industry</h2>
                    <p>Our flexible AI agents are ready to meet your diverse business needs.</p>
                </div>
                <div className="services-row">
                    {props.data
                        ? props.data.map((d, i) => {
                            const servicePath = d.name.toLowerCase();
                            const hasPage = availablePages.includes(servicePath);

                            const serviceContent = (
                                <>
                                    <i className={d.icon}></i>
                                    <div className="service-desc">
                                        <h3>{d.name}</h3>
                                        <p>{d.text}</p>
                                    </div>
                                </>
                            );

                            return (
                                <div key={`${d.name}-${i}`} className="col-md-3 services-item">
                                    {hasPage ? (
                                        <Link to={`/${servicePath}`} className="service-link">
                                            {serviceContent}
                                        </Link>
                                    ) : (
                                        serviceContent
                                    )}
                                </div>
                            );
                        })
                        : "loading"}
                </div>
            </div>
        </div>
    );
};