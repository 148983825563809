import React from "react";
import { Navigation } from "./navigation";
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { Footer } from "./footer";

const initialState = {
    company: "",
    name: "",
    designation: "",
    mobile: "",
    email: "",
    country: "",
    message: "",
};

const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
    "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
    "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
    "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia",
    "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
    "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
    "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
    "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
    "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi",
    "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
    "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia",
    "Norway", "Oman", "Pakistan", "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru",
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia",
    "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland",
    "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago",
    "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

export const ContactPage = (props) => {
    const data = props.data;
    const [formState, setFormState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formState.company) newErrors.company = "Company name is required.";
        if (!formState.name) newErrors.name = "Full name is required.";
        if (!formState.designation) newErrors.designation = "Designation is required.";
        if (!formState.mobile || !/^\d+$/.test(formState.mobile)) newErrors.mobile = "Valid mobile number is required.";
        if (!formState.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) newErrors.email = "Valid email is required.";
        if (!formState.country) newErrors.country = "Country selection is required.";
        if (!formState.message) newErrors.message = "Message is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) return;
        setLoading(true);
        emailjs
            .sendForm("service_c9wvn1h", "template_omrcfy4", e.target, "oTVJyQycfwu4ZHKAS")
            .then(
                (result) => {
                    console.log(result.text);
                    setFormState(initialState);
                    setShowPopup(true);
                },
                (error) => {
                    console.log(error.text);
                    alert("An error occurred. Please try again.");
                }
            )
            .finally(() => setLoading(false));
    };

    const closePopup = () => setShowPopup(false);


    return (
        <div>
            <Helmet>
                <title>Get in Touch for AI Voice Bot Solutions</title>
                <meta name="description" content="Get in touch with Vozzo.ai to learn more about our AI-powered voice bots. Discover how we can transform your communication, enhance productivity, and improve customer engagement." />
            </Helmet>
            <Navigation />
            <div id="contact">
                <div className="container">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="section-title">
                                <h2>Have Questions? We’re Here to Help!</h2>
                            </div>
                            <form name="sentMessage" validate onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="company">Company Name*</label>
                                            <input
                                                type="text"
                                                id="company"
                                                name="company"
                                                className="form-control"
                                                value={formState.company}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.company && <span className="error">{errors.company}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Full Name*</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                value={formState.name}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.name && <span className="error">{errors.name}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="designation">Designation*</label>
                                            <input
                                                type="text"
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                value={formState.designation}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.designation && <span className="error">{errors.designation}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile*</label>
                                            <input
                                                type="tel"
                                                id="mobile"
                                                name="mobile"
                                                className="form-control"
                                                value={formState.mobile}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.mobile && <span className="error">{errors.mobile}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Email*</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                value={formState.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.email && <span className="error">{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="country">Country*</label>
                                            <select
                                                id="country"
                                                name="country"
                                                className="form-control"
                                                value={formState.country}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Country</option>
                                                {countries.map((country) => (
                                                    <option key={country} value={country}>
                                                        {country}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.country && <span className="error">{errors.country}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="message">
                                    <div className="form-group">
                                        <label htmlFor="message">Message*</label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="form-control"
                                            rows="2"
                                            value={formState.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        {errors.message && <span className="error">{errors.message}</span>}
                                    </div>
                                </div>
                                <div id="success">
                                    <button type="submit" className="btn btn-custom btn-lg">
                                        {loading ? "Sending..." : "Send Message"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Contact Info and Social Links */}
                    {/* <div className="col-md-3 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Contact:</h3>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone"></i> Phone
                                </span>{" "}
                                {props.data ? props.data.phone : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{" "}
                                {props.data ? props.data.email : "loading"}
                            </p>
                        </div>
                    </div> */}
                    < Footer />
                </div>
            </div>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>Your form has been submitted. Our team will reach out to you within 24 hours.</p>
                        <button className="btn btn-custom btn-lg" onClick={closePopup}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};