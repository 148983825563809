import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export const Intro = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector(".intro-section-subtitle");
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top < window.innerHeight * 0.8) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <section id="intro-section" className="intro-section">
            <div className="container text-center intro-section-container">
                <div className="intro-section-header">
                    <div className="intro-section-title">
                        <h2>What is Vozzo</h2>
                    </div>
                    <p className={`intro-section-subtitle ${isScrolled ? "scrolled" : ""}`}>
                        <span>AI</span><span>phone</span><span>agents</span><span>that</span><span>sound</span><span>human,</span>
                        <span>speak</span><span>any</span><span>language,</span><span>and</span><span>operate</span><span>24/7</span>
                    </p>
                    <p className="intro-section-description">
                        Vozzo seamlessly integrates cutting-edge conversational AI into your business, enabling you to create the ideal virtual employee. From sales and scheduling to customer support, Vozzo’s AI agents handle it all—effortlessly, naturally, and in any language.
                    </p>
                    {/* <p className="intro-section-description">
                        All for just $0.06 a minute.
                    </p> */}
                </div>
                <div className="cta-btn-section">
                    <Link to="/live-demo">
                        <button className="btn-custom demo-btn">
                            Live Demo
                        </button>
                    </Link>
                    <Link to="/contact">
                        <button className="btn-custom enterprise-btn">
                            Enterprise <FaArrowRight size={16} />
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};