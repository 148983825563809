import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Footer } from "./footer";

const TermsOfService = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="terms-of-service">
            <Navigation />
            <div className="container terms-of-service-container">
                <div className="text-center">
                    <div className="section-title">
                        <h2 className="terms-of-service-title">Terms of Service</h2>
                    </div>
                </div>
                <div className="terms-of-service-meta">
                    <p className="terms-of-service-info">
                        Vozzo AI Labs
                    </p>
                    <p className="terms-of-service-date">Last Updated: 12th February 2025</p>
                </div>
                <div className="row">
                    <div className="terms-of-service-content">
                        <p><strong>Effective Date:</strong><span> 12th February 2025</span></p>

                        <h5>1. Acceptance of Terms</h5>
                        <p>
                            By accessing or using vozzo.ai and its associated services
                            (“Service”), you agree to be bound by these Terms of Service
                            (“Terms”). If you do not agree with these Terms, please do not use
                            our Service.
                        </p>

                        <h5>2. Description of the Service</h5>
                        <p>
                            Vozzo AI Labs Private Limited provides AI Voice Agents that enable
                            you to create and manage multiple voice agents for automating
                            voice interactions. Our Service includes:
                        </p>
                        <ul>
                            <li>
                                <strong>User Registration and Profile Management:</strong><span> Secure
                                    account creation using your Email, Name, and Mobile Number.</span>
                            </li>
                            <li>
                                <strong>AI Voice Agent Deployment:</strong><span> Tools to design,
                                    manage, and monitor voice agents.</span>
                            </li>
                            <li>
                                <strong>Third-Party Integrations:</strong><span> Integration with
                                    Google OAuth 2.0 for authentication and Google Calendar for
                                    scheduling.</span>
                            </li>
                            <li>
                                <strong>Analytics:</strong><span> Collection of call usage data to
                                    improve and refine our services.</span>
                            </li>
                        </ul>

                        <h5>3. Account Registration and User Responsibilities</h5>
                        <ul>
                            <li>
                                <strong>Accurate Information:</strong><span> You must provide accurate
                                    and complete information when registering an account.</span>
                            </li>
                            <li>
                                <strong>User Verification:</strong><span> We use your Email, Name, and
                                    Mobile Number to verify your identity and secure your account.</span>
                            </li>
                            <li>
                                <strong>Multiple Agents:</strong><span> You may create and manage
                                    multiple AI Voice Agents under a single account.</span>
                            </li>
                            <li>
                                <strong>Account Security:</strong><span> You are responsible for
                                    safeguarding your account credentials and for any activity that
                                    occurs under your account.</span>
                            </li>
                        </ul>

                        <h5>4. Use of the Service</h5>
                        <ul>
                            <li>
                                <strong>Permitted Use:</strong><span> You agree to use our Service only
                                    for lawful purposes and in accordance with these Terms.</span>
                            </li>
                            <li>
                                <strong>Prohibited Activities:</strong><span> You must not misuse the
                                    Service, attempt unauthorized access, or engage in activities
                                    that could damage the Service or interfere with other users.</span>
                            </li>
                            <li>
                                <strong>Third-Party Services:</strong><span> Use of integrations such
                                    as Google OAuth 2.0 and Google Calendar is subject to their own
                                    terms. By using these features, you consent to the necessary
                                    data exchanges with these services.</span>
                            </li>
                        </ul>

                        <h5>5. Intellectual Property</h5>
                        <p>
                            All content, software, trademarks, and other materials on the Site
                            are the property of Vozzo AI Labs Private Limited or its licensors.
                            You are granted a limited, non-exclusive license to access and use
                            the Service in compliance with these Terms.
                        </p>

                        <h5>6. Disclaimers and Limitation of Liability</h5>
                        <ul>
                            <li>
                                <strong>As-Is Service:</strong><span> The Service is provided “as is”
                                    without any warranties, whether express or implied.</span>
                            </li>
                            <li>
                                <strong>Limitation of Liability:</strong><span> To the fullest extent
                                    permitted by law, Vozzo AI Labs Private Limited shall not be
                                    liable for any indirect, incidental, or consequential damages
                                    arising from your use of the Service.</span>
                            </li>
                        </ul>

                        <h5>7. Modifications to the Service and Terms</h5>
                        <p>
                            We reserve the right to modify or discontinue the Service or any
                            part thereof at any time. We may also update these Terms
                            periodically, with changes becoming effective upon posting. Your
                            continued use of the Service after such changes constitutes
                            acceptance of the new Terms.
                        </p>

                        <h5>8. Governing Law</h5>
                        <p>
                            These Terms are governed by and construed in accordance with the
                            laws applicable in the jurisdiction where Vozzo AI Labs Private
                            Limited is registered. Any disputes arising from these Terms will
                            be subject to the exclusive jurisdiction of the local courts.
                        </p>

                        <h5>9. Contact Information</h5>
                        <p>For any questions or concerns regarding these Terms, please contact us at:</p>
                        <p>
                            <strong>Company:</strong> Vozzo AI Labs Private Limited
                            <br />
                            <strong>Address:</strong> J/901 AVADH CAROLINA, NR DPS SCHOOL,
                            SILENT ZON, Dumas, Choryasi, Surat-394550, Gujarat
                            <br />
                            <strong>Contact Mobile:</strong> +91-9113997673
                        </p>
                    </div>
                </div>
                <hr />
                < Footer />
            </div>
        </div>
    );
};

export default TermsOfService;