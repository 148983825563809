// dev base URL
export const API_BASE_URL = "https://backend.dev.vozzo.ai/";

// Prod base URL
// export const API_BASE_URL = "https://backend.vozzo.ai/";

export const API_ENDPOINT = {
    vozzo_agent_session: "vozzo-agent-session",
    list_agent: "website/list-agent/",
    upload_audio: "call/upload_audio/",
    vozzo_agent_sdk_session: "vozzo-agent-session-for-sdk",
  };