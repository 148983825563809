import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Footer } from "./footer";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="privacy-policy">
            <Navigation />
            <div className="container privacy-policy-container">
                <div className="text-center">
                    <div className="section-title">
                        <h2 className="privacy-policy-title">Privacy Policy</h2>
                    </div>
                </div>
                <div className="privacy-policy-meta">
                    <p className="privacy-policy-info">
                        Vozzo AI Labs
                    </p>
                    <p className="privacy-policy-date">Last Updated: 12th February 2025</p>
                </div>
                <div className="row">
                    <div className="privacy-policy-content">
                        <p>
                            Welcome to Vozzo AI Labs. Your privacy is important to us. This
                            Privacy Policy explains how we collect, use, disclose, and
                            safeguard your information when you use our services, including
                            access to your calendar events with read and write permissions.
                        </p>
                        <p>
                            By using our services, you consent to the practices described in
                            this Privacy Policy.
                        </p>

                        <h5>1. Information We Collect</h5>
                        <p>When you use Vozzo, we may collect the following types of information:</p>
                        <ul>
                            <li>
                                <strong>Personal Information:</strong><span> Name, email address, and
                                    contact details when you sign up.</span>
                            </li>
                            <li>
                                <strong>Calendar Data:</strong><span> If you grant us permission, we
                                    access your calendar events for read and write functionality.</span>
                            </li>
                            <li>
                                <strong>Usage Data:</strong><span> Information about how you interact
                                    with our services.</span>
                            </li>
                        </ul>

                        <h5>2. How We Use Your Information</h5>
                        <p>We use your information to:</p>
                        <ul>
                            <li>Provide and improve our services.</li>
                            <li>Manage and update your calendar events as per your interactions.</li>
                            <li>Enhance user experience and develop new features.</li>
                            <li>Ensure compliance with legal obligations.</li>
                        </ul>

                        <h5>3. Sharing of Information</h5>
                        <p>We do not sell, trade, or rent your personal information. We may share information:</p>
                        <ul>
                            <li>
                                <strong>No Selling or Renting:</strong><span> We do not sell, trade,
                                    or rent your personal information.</span>
                            </li>
                            <li>
                                <strong>Third-Party Service Providers:</strong><span> We may share
                                    information with trusted partners that help us operate our
                                    services.</span>
                            </li>
                            <li>
                                <strong>Legal Compliance:</strong><span> We may share data if required
                                    by law or to protect our legal rights.</span>
                            </li>
                            <li>
                                <strong>User Consent:</strong><span> Any other sharing will require
                                    your explicit consent.</span>
                            </li>
                        </ul>

                        <h5>4. Google Calendar Integration</h5>
                        <p>Our application requests read and write access to your Google Calendar solely to:</p>
                        <ul>
                            <li>
                                <strong>Read and Write Access:</strong><span> Our application requests
                                    access to fetch and display your scheduled events.</span>
                            </li>
                            <li>
                                <strong>Event Management:</strong><span> We create, update, or delete
                                    calendar events as per your requests.</span>
                            </li>
                            <li>
                                <strong>Data Integrity:</strong><span> We do not alter any data beyond
                                    user-initiated actions.</span>
                            </li>
                        </ul>

                        <h5>5. Data Security</h5>
                        <p>
                            We implement industry-standard security measures to protect your
                            data. However, no transmission over the internet or method of
                            electronic storage is 100% secure.
                        </p>

                        <h5>6. Your Rights and Choices</h5>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Review, update, or delete your personal data.</li>
                            <li>Revoke calendar access at any time via Google account settings.</li>
                            <li>Opt-out of specific data collection through app preferences.</li>
                        </ul>

                        <h5>7. Compliance with Third-Party Policies</h5>
                        <p>
                            This Privacy Policy aligns with policies required by Google and
                            other third-party vendors we may integrate with in the future. We
                            adhere to their data usage guidelines and security requirements.
                        </p>

                        <h5>8. Changes to This Policy</h5>
                        <p>
                            We may update this Privacy Policy periodically. Any changes will
                            be reflected by an updated "Last Updated" date, and we will
                            provide additional notice if necessary.
                        </p>

                        <h5>9. Contact Us</h5>
                        <p>For any questions or concerns regarding these Terms, please contact us at:</p>
                        <p>
                            <strong>Company:</strong><span> Vozzo AI Labs Private Limited</span>
                            <br />
                            <strong>Address:</strong><span> J/901 AVADH CAROLINA, NR DPS SCHOOL, SILENT ZON, Dumas, Choryasi, Surat-394550, Gujarat</span>
                            <br />
                            <strong>Contact Mobile:</strong><span> +91-9113997673</span>
                        </p>
                    </div>
                </div>
                <hr />
                < Footer />
            </div>
        </div>
    );
};

export default PrivacyPolicy;