import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";
import { FaPlay, FaPause } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { RiShieldKeyholeLine } from "react-icons/ri"

export const VozzoXcent = () => {
    const [activeTab, setActiveTab] = useState("indian-accent");
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isVozzoEnabled, setIsVozzoEnabled] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState("Aadhya");

    const toggleAudio = () => {
        setIsPlaying(!isPlaying);
    };

    const handleToggleVozzo = () => {
        setIsVozzoEnabled(!isVozzoEnabled);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="VozzoXcent">
            <Navigation />

            {/* Header Section */}
            <div className="xcent-header">
                <div className="xcent-header-left">
                    <h1>Clearer Conversations with VozzoXcent</h1>
                    <p>
                        Break language barriers effortlessly—Vozzo AI refines accents while keeping voices natural,
                        ensuring smoother, more authentic interactions for customers and call centers alike.
                    </p>
                    <Link to="/contact">
                        <button>Book a demo</button>
                    </Link>
                    <ul>
                        <li>Ideal for BPOs and offshore call centers</li>
                        <li>Supports Indian and Filipino English accents, with more on the way</li>
                        <li>Retains the speaker’s unique voice identity</li>
                    </ul>
                </div>
                <div className="xcent-header-right">
                    <img src="./img/VozzoXcent.jpg" alt="VozzoXcent" />
                </div>
            </div>

            {/* Integration Section */}
            <div className="xcent-integration">
                <div className="xcent-integration-left">
                    <h3>Auto-integration with all Softphone platforms</h3>
                    <p>
                        Vozzo seamlessly integrates with any softphone, enhancing calls by eliminating background noise, adapting accents, translating speech, and providing real-time transcription—all at the click of a button.
                    </p>
                </div>
                <div className="xcent-integration-right">
                    <img src="./img/xcent-integration.jpg" alt="VozzoXcent" />
                </div>
            </div>

            {/* Accent Conversion Section */}
            {/* <div className="xcent-conversion">
                <h3>Experience the magic of AI Accent Conversion</h3>
                <div className="xcent-tab-container">
                    <div
                        className={`xcent-tab ${activeTab === "indian-accent" ? "active" : ""}`}
                        onClick={() => setActiveTab("indian-accent")}
                    >
                        Indian English Accent
                    </div>
                    <div
                        className={`xcent-tab ${activeTab === "uk-accent" ? "active" : ""}`}
                        onClick={() => setActiveTab("uk-accent")}
                    >
                        UK English Accent
                    </div>
                </div>

                <div className="xcent-content-section">
                    {activeTab === "indian-accent" && (
                        <div className="indian-accent">
                            <div className="indian-accent-header">
                                <h2>Voice Preservation and Voice Profiles modes</h2>
                            </div>
                            <div className="indian-accent-content">
                                <div className="indian-accent-left">
                                    <img src="./img/VozzoXcent.jpg" alt="Indian Accent" />
                                </div>
                                <div className="indian-accent-right">
                                    <img src="./img/VozzoXcent.jpg" alt="Indian Accent" />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "uk-accent" && (
                        <div className="uk-accent">
                            <div className="uk-accent-header">
                                <h2>Voice Profiles mode</h2>
                            </div>
                            <div className="uk-accent-content">
                                <div className="uk-accent-left">
                                    <img src="./img/VozzoXcent.jpg" alt="UK Accent" />
                                </div>
                                <div className="uk-accent-right">
                                    <img src="./img/VozzoXcent.jpg" alt="UK Accent" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="xcent-audio-demo">
                    <h4>Audio demos with different voices</h4>
                    <div className="audio-player">
                        <button className="play-btn" onClick={toggleAudio}>
                            {isPlaying ? <FaPause /> : <FaPlay />}
                        </button>
                        <input
                            type="range"
                            className="progress-bar"
                            value={progress}
                            onChange={(e) => setProgress(e.target.value)}
                            min="0"
                            max="100"
                        />
                    </div>

                    <div className="vozzo-toggle">
                        <span className={!isVozzoEnabled ? "active" : ""}>Without Vozzo</span>
                        <label className="switch">
                            <input type="checkbox" checked={isVozzoEnabled} onChange={handleToggleVozzo} />
                            <span className="slider"></span>
                        </label>
                        <span className={isVozzoEnabled ? "active" : ""}>With Vozzo</span>
                    </div>

                    <div className="agent-selection">
                        <h5>Agents</h5>
                        <div className="agent-options">
                            {["Aadhya", "Meera", "Tara", "Vedant"].map((agent) => (
                                <label key={agent} className="radio-container">
                                    <input
                                        type="radio"
                                        name="agent"
                                        value={agent}
                                        checked={selectedAgent === agent}
                                        onChange={() => setSelectedAgent(agent)}
                                    />
                                    <span className="radio-label">{agent}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}

            <div id="xcent-security" className="xcent-security">
                <h3>Built with powerful, enterprise-grade security in mind</h3>

                <div className="security-content">
                    <div className="security-left">
                        <h4>
                            <FaCog className="security-icon" /> Governance
                        </h4>
                        <ul>
                            <li>Quick, easy deployment to all computers at once</li>
                            <li>User access and management</li>
                            <li>Centralized billing</li>
                            <li>Email, OAuth, and SSO user verification</li>
                        </ul>
                    </div>
                    <div className="security-right">
                        <h4>
                            <RiShieldKeyholeLine className="security-icon" /> Privacy & Security
                        </h4>
                        <ul>
                            <li>No voice data visible to Krisp servers</li>
                            <li>GDPR compliant</li>
                            <li>SOC-2 certified</li>
                            <li>Encryption in-transit and at-rest</li>
                        </ul>
                    </div>
                </div>
                {/* <button className="security-btn">Learn more</button> */}
            </div>

            <div className="xcent-cta">
                <h3>You’re one step away from elevating your customer calls!</h3>
                <Link to="/contact">
                    <button>Book a demo</button>
                </Link>
            </div>
        </div>
    );
};