import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { VozzoXcent } from "./VozzoXcent";
import { VozzoAgent } from "./VozzoAgent";
import { VozzoSync } from "./VozzoSync";

export const AiServices = () => {
    const [activeTab, setActiveTab] = useState("VozzoAgent");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="ai-services">
            <Navigation />

            {/* Tabs Section */}
            <div className="tabs-container">
                <div 
                    className={`tab ${activeTab === "VozzoAgent" ? "active" : ""}`} 
                    onClick={() => setActiveTab("VozzoAgent")}
                >
                    VozzoAgent
                </div>
                <div 
                    className={`tab ${activeTab === "VozzoXcent" ? "active" : ""}`} 
                    onClick={() => setActiveTab("VozzoXcent")}
                >
                    VozzoXcent
                </div>
                <div 
                    className={`tab ${activeTab === "VozzoShush" ? "active" : ""}`} 
                    onClick={() => setActiveTab("VozzoShush")}
                >
                    VozzoSync
                </div>
            </div>

            {/* Content Section */}
            <div className="ai-services-content-section">
                {activeTab === "VozzoAgent" && <VozzoAgent />}
                {activeTab === "VozzoXcent" && <VozzoXcent />}
                {activeTab === "VozzoShush" && <VozzoSync />}
            </div>
        </div>
    );
};