import React, { useState, useEffect, useRef } from "react";
import { use } from "react";
import "./VozzoAgentLiveWeb.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL, API_ENDPOINT } from "../../data/constants";


const WaveMicComponent = () => {
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch microphone stream when the component mounts.
  useEffect(() => {
    const fetchStream = async () => {
      try {
        const s = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        console.log("Stream received:", s);
        setStream(s);
      } catch (error) {
        console.error("Microphone permission error:", error);
        toast.error("Please grant microphone permission to connect.");
      } finally {
        setLoading(false);
      }
    };

    fetchStream();
  }, []);

  // Set up the audio processing once the stream is available.
  useEffect(() => {
    if (!stream) return;

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();

    // Some browsers require the context to be resumed.
    audioCtx.resume().then(() => console.log("AudioContext resumed"));

    const source = audioCtx.createMediaStreamSource(stream);
    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 32; // frequencyBinCount will be 16
    source.connect(analyser);

    // Create a Uint8Array with the frequencyBinCount value.
    const freqCount = analyser.frequencyBinCount;
    if (freqCount <= 0) {
      console.error("frequencyBinCount is 0 or less");
      return;
    }
    const dataArray = new Uint8Array(freqCount);

    const animate = () => {
      requestAnimationFrame(animate);
      analyser.getByteFrequencyData(dataArray);

      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i];
      }
      // Protect against division by zero
      const avg = dataArray.length > 0 ? sum / dataArray.length : 0;
      const scale = 1 + (avg / 255) * 2.0;

      const circularWave = document.getElementById("circular-wave");
      if (circularWave) {
        circularWave.style.transform = `translate(-50%, -50%) scale(${scale})`;
        const alpha = 0.95 - (avg / 255) * 0.1;
        circularWave.style.background = `radial-gradient(circle, rgba(135,206,250,0.7) 0%, rgba(135,206,250,${alpha}) 70%, transparent 100%)`;
      }
    };

    animate();

    return () => {
      audioCtx.close();
    };
  }, [stream]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wave-mic-cont">
      <div className="mic-cont">
        <div className="circular-wave" id="circular-wave"></div>
        <div className="mic-button">
          <img
            src="https://img.icons8.com/ios-filled/50/000000/microphone.png"
            alt="Mic Icon"
            className="mic-icon"
          />
        </div>
      </div>
    </div>
  );
};

// DummyMicComponent (Static)
const DummyMicComponent = () => {
  return (
    <div className="wave-mic-cont">
      <div className="mic-cont">
        <div
          className="circular-wave"
          id="circular-wave-dummy"
          style={{
            transform: `translate(-50%, -50%) scale(1)`,
            background: `radial-gradient(circle, rgba(135,206,250,0.7) 0%, rgba(135,206,250,0.95) 70%, transparent 100%)`
          }}
        ></div>
        <div className="mic-button">
          <img
            src="https://img.icons8.com/ios-filled/50/000000/microphone.png"
            alt="Mic Icon"
            className="mic-icon"
          />
        </div>
      </div>
    </div>
  );
};

export const VozzoAgentWeb = ({ agent_uuid }) => {

  /* START: Page Onload Default Form integration-----------------------------------------*/
  const [micStream, setMicStream] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false); // Manage popup visibility
  const [formData, setFormData] = useState({ name: "", companyName: "", mobileNumber: "" });
  const [isLoading, setLoading] = useState(false);
  const [isConnected, setConnected] = useState(false);
  const peerConnectionRef = useRef(null); // Reference to the peer connection
  const mediaStreamRef = useRef(null); // Reference to the local media stream
  const audioElRef = useRef(null); // Reference for the audio element
  const dataChannelRef = useRef(null);
  const endCallTimeoutRef = useRef(null);
  const recordedChunksRefUser = useRef([]);
  const recordedChunksRefAgent = useRef([]);
  const combinedStreamRef = useRef(null);
  const startTimeRef = useRef(null);

  // final submit to start conversation
  const handleConversationSubmit = (e) => {
    e.preventDefault();
    startConversation();
  };

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    setPopupVisible(false); // Show the popup form on page load
  }, []);

  // Request microphone permission
  const requestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicStream(stream);
      stream.getTracks().forEach((track) => track.stop()); // Stop tracks immediately after permission check
      return true; // Permission granted
    } catch (error) {
      if (error.name === "NotAllowedError") {
        toast.error("Microphone access denied. Please enable permissions.");
      } else if (error.name === "NotFoundError") {
        toast.error("No microphone found. Please connect a microphone.");
      } else {
        toast.error("Error while requesting microphone permissions.");
      }
      // //console.error("Permission error:", error);
      return false; // Permission denied
    }
  };

  // Request to play audio, which triggers sound output permission
  const requestAudioOutputPermission = async () => {
    try {
      const audioEl = document.createElement("audio");
      audioElRef.current = audioEl; // Save the reference for later use
      audioEl.autoplay = true;

      // Try to play audio to trigger sound settings
      const playPromise = audioEl.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // //console.log("Audio is playing successfully.");
          })
          .catch((error) => {
            // Autoplay blocked
            toast.error("Autoplay audio is blocked. Please interact with the page to enable sound.");
          });
      }

      return true; // Permission to play sound triggered
    } catch (error) {
      toast.error("Error while requesting audio output permissions.");
      // //console.error("Audio permission error:", error);
      return false;
    }
  };

  const startConversation = async () => {
    if (!(await requestPermission())) {
      return; // Exit if microphone permissions are not granted
    }

    if (!(await requestAudioOutputPermission())) {
      return; // Exit if audio output permissions are not granted
    }

    try {
      setLoading(true);

      // Step 1: Fetch an ephemeral key from the server
      const EPHEMERAL_KEY = await fetchEphemeralKey();

      // Step 2: Create a WebRTC peer connection
      const pc = new RTCPeerConnection();
      peerConnectionRef.current = pc;

      // Save the start time of the conversation
      startTimeRef.current = new Date().toISOString();

      // Step 3: Set up to play remote audio from the model
      const audioEl = document.createElement("audio");
      audioEl.autoplay = true;
      audioElRef.current = audioEl; // Save the reference for playing audio

      // Step 4: Add local audio track for microphone input
      combinedStreamRef.current = new MediaStream();
      mediaStreamRef.current = new MediaStream();
      const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      localStream.getTracks().forEach(track => pc.addTrack(track, localStream));
      //localStream.getAudioTracks().forEach(track => mediaStreamRef.current.addTrack(track));
      //mediaStreamRef.current = localStream;

      // Step 4.1: Create a combined stream to record the conversation
      pc.ontrack = (event) => {
        const remoteStream = event.streams[0]; // Get the remote stream
        remoteStream.getTracks().forEach((track) => {
          combinedStreamRef.current.addTrack(track); // Add to the combined stream
          localStream.getAudioTracks().forEach(track => mediaStreamRef.current.addTrack(track));
        });
        audioEl.srcObject = remoteStream; // Play the audio
        ////console.log("Remote track added to combined stream:", track);
        //console.log("pc.ontrack: Added remote track to combined stream:", remoteStream);
        //console.log("Tracks in combined stream:", combinedStreamRef.current.getTracks());
      };

      // Step 5: Create an Session Description Protocol (SDP) offer and set it as the local description
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      // Step 6: Send the SDP offer and metadata to the OpenAI Realtime API
      const baseUrl = "https://api.openai.com/v1/realtime";
      const model = "gpt-4o-realtime-preview-2024-12-17";
      const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
        method: "POST",
        body: offer.sdp, // Send the SDP offer as plain text
        headers: {
          Authorization: `Bearer ${EPHEMERAL_KEY}`,
          "Content-Type": "application/sdp",
        },
      });
      if (!sdpResponse.ok) {
        const errorResponse = await sdpResponse.json();
        // //console.error("Error:", errorResponse);
        throw new Error("Failed to fetch SDP answer");
      }

      // Step 7: Set the remote description using the SDP answer from the server
      const answer = await sdpResponse.text();
      await pc.setRemoteDescription({
        type: "answer",
        sdp: answer,
      });
      setConnected(true); // Mark as connected

      // Step 8: Create a data channel from a peer connection
      //console.log("creating data channel:", pc);
      const dc = pc.createDataChannel("oai-events");
      //console.log("data channel created:");
      // Save the data channel reference
      setupDataChannel(dc);

      // Step 9: Save the data channel reference
      pc.onconnectionstatechange = () => {
        if (pc.connectionState === "connected") {
          startRecording(combinedStreamRef.current, mediaStreamRef.current);
          //console.log("Call recording started with both streams");
        }
      };

      // Step 10: Set a timeout to end the call after 3 minutes (180,000 milliseconds)
      endCallTimeoutRef.current = setTimeout(() => {
        //console.log("Ending call after 2 minutes");
        toast.info("2 minutes limit reached.");
        endConnection();
      }, 120000);

    } catch (error) {
      //console.log("error to start the conversation:", error);
      toast.error("Please try again.");
      endConnection();

    } finally {
      setLoading(false);
      //endConnection();
    }
  };

  // Fetch ephemeral key
  const fetchEphemeralKey = async () => {
    //console.log('start fetchEphemeralKey');
    const metadata = {
      language: document.querySelector('select[aria-label="Select Template"]').value,
      voice: 'alloy',
      gender: 'female',
      accent: 'indian',
      agent_prompt: '',
    };
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINT.vozzo_agent_session}`, {
      method: "POST",
      body: JSON.stringify({ ...metadata, ...formData }),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) throw new Error("Failed to fetch ephemeral key");
    const data = await response.json();
    //console.log('end fetchEphemeralKey');
    return data?.client_secret?.value;
  };

  // Setup data channel
  const setupDataChannel = (dc) => {
    dc.addEventListener("message", (e) => {
      const realtimeEvent = JSON.parse(e.data);
      //console.log("on message from data channel: ", realtimeEvent);
    });

    dc.addEventListener("open", () => {
      const responseCreate = {
        type: "response.create",
        response: {
          modalities: ["text"],
          instructions: "hello, how can i help you?",
        },
      };
      dc.send(JSON.stringify(responseCreate));
    });
  };

  // Start recording
  const startRecording = (combinedStream, mediaStream) => {
    combinedStream.getTracks().forEach(track => {
      //console.log(`combinedStream: Track ID: ${track.id}, Kind: ${track.kind}, ReadyState: ${track.readyState}`);
    });
    mediaStream.getTracks().forEach(track => {
      //console.log(`mediaStream: Track ID: ${track.id}, Kind: ${track.kind}, ReadyState: ${track.readyState}`);
    });

    const mediaRecorderAgent = new MediaRecorder(combinedStream);
    mediaRecorderAgent.ondataavailable = (event) => {
      //console.log("Data available event fired: agent audio: ", event.data.size);
      if (event.data.size > 0) {
        //console.log("Recording event.data: ", event.data);
        recordedChunksRefAgent.current.push(event.data);
        uploadAudio();
      }
    };

    const mediaRecorderUser = new MediaRecorder(mediaStream);
    mediaRecorderUser.ondataavailable = (event) => {
      //console.log("Data available event fired: microphone audio: ", event.data.size);
      if (event.data.size > 0) {
        //console.log("Recording event.data: ", event.data);
        recordedChunksRefUser.current.push(event.data);
        //uploadAudio();
      }
    };

    mediaRecorderAgent.start();
    mediaRecorderUser.start();
  };

  const uploadAudio = async () => {
    try {
      // Upload the recorded audio to S3
      const blob_agent = new Blob(recordedChunksRefAgent.current, { type: "audio/webm" });
      const blob_user = new Blob(recordedChunksRefUser.current, { type: "audio/webm" });

      //console.log("Recording blob agent:", blob_agent);
      //console.log("Recording blob user:", blob_user);

      const endTime = new Date().toISOString();
      const duration = Math.floor((Date.parse(endTime) - Date.parse(startTimeRef.current)) / 1000);

      // Create a FormData object and append the file
      const formData = new FormData();
      formData.append('file_agent', blob_agent, 'recording_agent.webm');
      formData.append('file_user', blob_user, 'recording_user.webm');
      formData.append('start_time', startTimeRef.current);
      formData.append('end_time', endTime);
      formData.append('duration', duration.toString());
      formData.append('language', document.querySelector('select[aria-label="Select Template"]').value);

      //Get the size of the FormData object
      //const formDataSize = await getFormDataSize(formData);
      //console.log(`FormData size: ${formDataSize} bytes`);

      const audioUploadResponse = await fetch(`${API_BASE_URL}${API_ENDPOINT.upload_audio}${agent_uuid}`, {
        method: "POST",
        body: formData, // Send the SDP offer as plain text
        headers: {},
      });

      if (!audioUploadResponse.ok) {
        // throw new Error("Failed to upload audio");
        //console.log("failed uploaded to S3");
      }
      else {
        //console.log("Call recording uploaded to S3");
      }

      // toast.info("Conversation ended.");
      setConnected(false); // Mark as disconnected
    }
    catch (error) {
      //console.error("Error while uploading the audio:", error);
      //toast.error("Failed to upload the audio.");
    }
  };

  const endConnection = async () => {
    try {
      // Close the peer connection
      //console.log("Ending the connection...");
      if (endCallTimeoutRef.current) {
        clearTimeout(endCallTimeoutRef.current);
        endCallTimeoutRef.current = null;
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
      if (dataChannelRef.current) {
        dataChannelRef.current.close();
        dataChannelRef.current = null;
      }
      // Stop all tracks in the media stream
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      if (combinedStreamRef.current) {
        combinedStreamRef.current.getTracks().forEach((track) => track.stop());
        combinedStreamRef.current = null;
      }
      if (recordedChunksRefAgent.current) {
        recordedChunksRefAgent.current = [];
      }
      if (recordedChunksRefUser.current) {
        recordedChunksRefUser.current = [];
      }

      //toast.info("Conversation Ended.");
      setConnected(false); // Mark as disconnected

    } catch (error) {
      //console.error("Error while ending the connection:", error);
      toast.error("Failed to end the conversation.");
    }
  };
  /* END: WebRTC realtime integration------------------------------------------*/

  return (
    <div className="floating-button">
      <div className="floating-button">
        {isPopupVisible ? (
          // Close button when popup is open
          <div className="animated-button-container" onClick={togglePopup}>
            <div className="container">
              <div className="circle circle1"></div>
              <div className="circle circle2"></div>
              <div className="circle circle3"></div>
              <div className="circle circle4"></div>
              <div className="button">
                ✖
              </div>
            </div>
          </div>
        ) : (
          // Animated button when popup is closed
          <div className="animated-button-container" onClick={togglePopup}>
            <div className="container">
              <div className="circle circle1"></div>
              <div className="circle circle2"></div>
              <div className="circle circle3"></div>
              <div className="circle circle4"></div>
              <div className="btn-wave"></div>
              <div className="btn-wave"></div>
              <div className="btn-wave"></div>
              <div className="button">
                <img
                  src="https://img.icons8.com/?size=100&id=yfR873L4EkXc&format=png&color=000000"
                  alt="Mic Icon"
                  className="mic-icon"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {isPopupVisible && (
        <div className="popup">
          <div className="popup-header">
            {/* Render wave animation if connected; otherwise render dummy mic */}
            {isConnected ? (
              <WaveMicComponent />
            ) : (
              <DummyMicComponent />
            )}
          </div>

          <form onSubmit={handleConversationSubmit}>
            <select aria-label="Select Template" className="language-select" disabled={isLoading}>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
              <option value="gujarati">Gujarati</option>
              <option value="bengali">Bengali</option>
              <option value="tamil">Tamil</option>
              <option value="spanish">Spanish</option>
              <option value="french">French</option>
            </select>
            {!isConnected && (
              <button type="submit" className="btn btn-success speak-btn" disabled={isLoading}>
                {isLoading ? "Connecting..." : (
                  <>
                    <i className="fas fa-phone"></i>
                    Speak to AI
                  </>
                )}
              </button>
            )}
          </form>
          {isConnected && (
            <>
              <div className="listening-animation">
                Listening<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
              </div>
              <button className="btn btn-danger end-conversation-btn" onClick={endConnection}>
                Hang Up
              </button>
            </>
          )}
          <div className="powered-by">
            <img src="../favicon.ico" alt="Vozzo Icon" className="vozzo-icon" />
            <p>Powered by Vozzo.AI</p>
          </div>
        </div>
      )}
      <ToastContainer position="top-right" autoClose={2000} />
    </div>
  );
};

// Export the module if using a module system
if (typeof module !== 'undefined' && module.exports) {
  module.exports = VozzoAgentWeb;
}