import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";
import { FaCog } from "react-icons/fa";
import { RiShieldKeyholeLine } from "react-icons/ri"

export const VozzoAgent = () => {
    const [activeFeatureTab, setActiveFeatureTab] = useState("language-voice");
    const [activeUseCaseTab, setActiveUseCaseTab] = useState("call-center-bpo");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="VozzoAgent">
            <Navigation />

            <div className="vozzo-agent-header">
                <h1>Smarter Calls, Happier Customers, Less Headaches</h1>
                <p>Revolutionize customer interactions with AI-powered voice solutions that enhance clarity, efficiency, and satisfaction—no more frustrating calls!</p>
                <Link to="/contact">
                    <button>Book a demo</button>
                </Link>
                <img src="./img/VozzoAgent.jpg" alt="VozzoAgent" />
            </div>

            {/* Features Section */}
            <div className="vozzo-agent-features">
                <div className="section-title">
                    <h2>Features</h2>
                </div>
                {/* Tabs Container */}
                <div className="vozzo-agent-tabs">
                    <div
                        className={`agent-tab ${activeFeatureTab === "language-voice" ? "active" : ""}`}
                        onClick={() => setActiveFeatureTab("language-voice")}
                    >
                        Language & Voice
                    </div>
                    <div
                        className={`agent-tab ${activeFeatureTab === "ai-models" ? "active" : ""}`}
                        onClick={() => setActiveFeatureTab("ai-models")}
                    >
                        AI Models
                    </div>
                    <div
                        className={`agent-tab ${activeFeatureTab === "custom-integrations" ? "active" : ""}`}
                        onClick={() => setActiveFeatureTab("custom-integrations")}
                    >
                        Custom Integrations
                    </div>
                    <div
                        className={`agent-tab ${activeFeatureTab === "deployment" ? "active" : ""}`}
                        onClick={() => setActiveFeatureTab("deployment")}
                    >
                        Deployment
                    </div>
                    <div
                        className={`agent-tab ${activeFeatureTab === "ai-analytics" ? "active" : ""}`}
                        onClick={() => setActiveFeatureTab("ai-analytics")}
                    >
                        AI Analytics
                    </div>
                </div>

                {/* Tab Content */}
                <div className="agent-tab-content">
                    {activeFeatureTab === "language-voice" && (
                        <div className="language-voice">
                            <div className="language-voice-left">
                                <h3>Language & Voice</h3>
                                <p>VozzoAgent supports multiple languages and voice styles to deliver high-quality speech synthesis.</p>
                                {/* <button>Book a demo</button> */}
                                <ul>
                                    <li>Support 100+ languages</li>
                                    <li>Wide range of voice options</li>
                                    <li>Local Dialects and Accent variations available</li>
                                    <li>Voice cloning</li>
                                    <li>Flexibility of tonality selection</li>
                                </ul>
                            </div>
                            <div className="language-voice-right">
                                <img src="./img/agent-language.jpg" alt="VozzoAgent-language" />
                            </div>
                        </div>
                    )}
                    {activeFeatureTab === "ai-models" && (
                        <div className="ai-models">
                            <div className="ai-models-left">
                                <h3>AI Models</h3>
                                <p>Leverage cutting-edge AI models for enhanced speech recognition, synthesis, and real-time processing.</p>
                                {/* <button>Book a demo</button> */}
                                <ul>
                                    <li>Flexibility to choose LLMs like OpenAI, Gemini, Deepseek, Claude, Quen, Llama and more</li>
                                    <li>Support multimodal framework</li>
                                    <li>Support all major speech-to-text modals like Deepgram, AssemblyAI, GoogleSTT, Whisper and more</li>
                                    <li>Support all major text-to-speech modals like ElevenLabs, PlayAI, Google TTS, OpenAI TTS and more</li>
                                </ul>
                            </div>
                            <div className="ai-models-right">
                                <img src="./img/ai-models.jpg" alt="VozzoAgent-models" />
                            </div>
                        </div>
                    )}
                    {activeFeatureTab === "custom-integrations" && (
                        <div className="custom-integrations">
                            <div className="custom-integrations-left">
                                <h3>Custom Integrations</h3>
                                <p>Seamlessly integrate with various CRMs, softphone platforms, and enterprise tools.</p>
                                {/* <button>Book a demo</button> */}
                                <ul>
                                    <li>Prebuilt Integrations available with CRMs like Salesforce, Zoho, Microsoft360, and more</li>
                                    <li>Prebuilt Integrations available with ERPs like Oracle, SAP, and more</li>
                                    <li>100+ quick no-code integrations, examples: Google calendar, Sheets, Slack, WhatsApp, Gmail and more</li>
                                </ul>
                            </div>
                            <div className="custom-integrations-right">
                                <img src="./img/custom-integration.jpg" alt="VozzoAgent-integration" />
                            </div>
                        </div>
                    )}
                    {activeFeatureTab === "deployment" && (
                        <div className="deployment">
                            <div className="deployment-left">
                                <h3>Deployment</h3>
                                <p>Flexible deployment options, including on-premise, cloud-based, or hybrid solutions.</p>
                                {/* <button>Book a demo</button> */}
                                <ul>
                                    <li>Telephony deployment for incoming and outgoing calls in a click</li>
                                    <li>WhatsApp call deployment in one click</li>
                                    <li>Webcall deployment with WebSDKs</li>
                                    <li>Android App based SDKs</li>
                                    <li>iOS app based SDKs</li>
                                    <li>Quick Configuration option from marketplaces like AWS, Oracle, Wordpress, Wix, Azure, GCP, Shopify, and more</li>
                                </ul>
                            </div>
                            <div className="deployment-right">
                                <img src="./img/deployment.jpg" alt="VozzoAgent-deployment" />
                            </div>
                        </div>
                    )}
                    {activeFeatureTab === "ai-analytics" && (
                        <div className="ai-analytics">
                            <div className="ai-analytics-left">
                                <h3>AI Analytics</h3>
                                <p>Advanced AI-driven analytics to provide real-time insights and reporting.</p>
                                {/* <button>Book a demo</button> */}
                                <ul>
                                    <li>Post call analytics</li>
                                    <li>On-call analytics</li>
                                    <li>Multilingual call transcripts, summery</li>
                                    <li>CSAT score, rootcause analysis</li>
                                    <li>Deep sentiment Analysis</li>
                                    <li>Customizable call success metrics</li>
                                    <li>Agent performance metrics and more</li>
                                </ul>
                            </div>
                            <div className="ai-analytics-right">
                                <img src="./img/ai-analytics.jpg" alt="VozzoAgent-ai-analytics" />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="usecases">
                <div className="section-title">
                    <h2>Use cases</h2>
                </div>
                <div className="vozzo-agent-usecases">
                    <div className="agent-usecases-tabs">
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "call-center-bpo" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("call-center-bpo")}
                        >
                            Call centers (BPOs)
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "call-center-enterprise" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("call-center-enterprise")}
                        >
                            Call centers (Enterprises)
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "fynbuddy-for-bfsi" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("fynbuddy-for-bfsi")}
                        >
                            FynBuddy for BFSI
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "insuremet" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("insuremet")}
                        >
                            InsureMet for Insurers
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "jetmet" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("jetmet")}
                        >
                            JetMet for Airlines
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "medicall" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("medicall")}
                        >
                            Medicall for Healthcare
                        </div>
                        <div
                            className={`usecases-tab ${activeUseCaseTab === "solution-all-ind" ? "active" : ""}`}
                            onClick={() => setActiveUseCaseTab("solution-all-ind")}
                        >
                            Solution for all industries
                        </div>
                    </div>

                    <div className="usecases-tab-content">
                        {activeUseCaseTab === "call-center-bpo" && (
                            <div className="call-center-bpo">
                                <h4>Revolutionizing BPO Call Centers</h4>
                                <p>Enhance customer interactions with AI-driven automation and intelligent voice agents.</p>
                                <ul>
                                    <li>24/7 automated customer support</li>
                                    <li>Seamless call routing and handling</li>
                                    <li>Reduced call wait times and improved efficiency</li>
                                    <li>AI-powered sentiment analysis for better service</li>
                                    <li>Multilingual support for global customers</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "call-center-enterprise" && (
                            <div className="call-center-enterprise">
                                <h4>AI-Driven Enterprise Call Centers</h4>
                                <p>Empower enterprise call centers with next-gen AI capabilities for superior customer engagement.</p>
                                <ul>
                                    <li>Advanced voice AI to handle high call volumes</li>
                                    <li>Integration with existing CRM and ERP systems</li>
                                    <li>Automated call summaries and insights</li>
                                    <li>Predictive analytics for customer satisfaction</li>
                                    <li>Custom AI models tailored for enterprise needs</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "fynbuddy-for-bfsi" && (
                            <div className="fynbuddy-for-bfsi">
                                <h4>FynBuddy: AI for BFSI Sector</h4>
                                <p>Enhance financial service operations with AI-driven automation and fraud detection.</p>
                                <ul>
                                    <li>Automated loan and credit approvals</li>
                                    <li>Fraud detection and risk analysis</li>
                                    <li>AI-powered customer onboarding</li>
                                    <li>Smart banking assistant for queries and transactions</li>
                                    <li>Personalized financial insights and recommendations</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "insuremet" && (
                            <div className="insuremet">
                                <h4>InsureMet: Your AI Insurance Agent</h4>
                                <p>Transform the insurance industry with automated workflows and AI-driven claim processing.</p>
                                <ul>
                                    <li>Instant policy information retrieval</li>
                                    <li>Automated claims processing and verification</li>
                                    <li>AI chatbots for customer support</li>
                                    <li>Fraud detection and risk assessment</li>
                                    <li>Seamless omnichannel communication</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "jetmet" && (
                            <div className="jetmet">
                                <h4>JetMet: AI for Airlines</h4>
                                <p>Optimize airline customer service with AI-driven automation and real-time assistance.</p>
                                <ul>
                                    <li>Automated flight booking and rescheduling</li>
                                    <li>Real-time flight status updates via AI agents</li>
                                    <li>Instant multilingual customer support</li>
                                    <li>Automated baggage tracking and claim assistance</li>
                                    <li>AI-powered feedback collection and analysis</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "medicall" && (
                            <div className="medicall">
                                <h4>MediCall: AI for Healthcare</h4>
                                <p>Revolutionize patient interactions with AI-powered virtual assistants and automation.</p>
                                <ul>
                                    <li>AI-powered appointment scheduling</li>
                                    <li>Automated patient reminders and follow-ups</li>
                                    <li>Real-time symptom assessment and triage</li>
                                    <li>Secure and compliant patient data management</li>
                                    <li>Multilingual support for diverse patient needs</li>
                                </ul>
                            </div>
                        )}
                        {activeUseCaseTab === "solution-all-ind" && (
                            <div className="solution-all-ind">
                                <h4>AI Solutions for All Industries</h4>
                                <p>Empower businesses across industries with cutting-edge AI-driven voice automation.</p>
                                <ul>
                                    <li>Custom AI solutions tailored to industry needs</li>
                                    <li>Seamless integration with existing enterprise systems</li>
                                    <li>Scalable solutions for small and large businesses</li>
                                    <li>Enhanced customer service with AI-powered insights</li>
                                    <li>Automated workflows for improved operational efficiency</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div id="xcent-security" className="xcent-security">
                <h3>Built with powerful, enterprise-grade security in mind</h3>

                <div className="security-content">
                    <div className="security-left">
                        <h4>
                            <FaCog className="security-icon" /> Governance
                        </h4>
                        <ul>
                            <li>Quick, easy deployment to all computers at once</li>
                            <li>User access and management</li>
                            <li>Centralized billing</li>
                            <li>Email, OAuth, and SSO user verification</li>
                        </ul>
                    </div>
                    <div className="security-right">
                        <h4>
                            <RiShieldKeyholeLine className="security-icon" /> Privacy & Security
                        </h4>
                        <ul>
                            <li>No voice data visible to Krisp servers</li>
                            <li>GDPR compliant</li>
                            <li>SOC-2 certified</li>
                            <li>Encryption in-transit and at-rest</li>
                        </ul>
                    </div>
                </div>
                {/* <button className="security-btn">Learn more</button> */}
            </div>

            <div className="xcent-cta">
                <h3>You’re one step away from elevating your customer calls!</h3>
                <Link to="/contact">
                    <button>Book a demo</button>
                </Link>
            </div>
        </div>
    );
};